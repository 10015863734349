<template>
  <v-app-bar app clipped-left elevation="0" color="topbar" height="50%">
    <v-btn icon color="topbarText" :to="{ path: '/' }">
      <v-icon>apps</v-icon>
    </v-btn>

    <v-app-bar-title id="welcome" class="topbarText--text text-no-wrap">
      NRF <span class="mx-2">|</span> Project Management
    </v-app-bar-title>

    <v-spacer />

    <div class="d-flex flex-columns">
      <v-tabs
        v-model="tabs"
        class="mx-2 align-self-center"
        background-color="topbar"
        align-with-title
      >
        <v-tab
          v-for="(item, i) in links"
          :key="i"
          class="text-capitalize white--text"
          active-class="orange--text"
          :to="item.to"
          v-show="!item.disabled"
        >
          <v-icon class="mx-2" :color="tabs === item.to ? 'orange' : 'white'">
            {{ item.icon }}
          </v-icon>

          {{ item.title }}
        </v-tab>
      </v-tabs>
    </div>

    <v-menu
      offset-y
      transition="slide-x-reverse-transition"
      v-if="user"
      left
      nudge-bottom="14"
      min-width="230"
      content-class="user-profile-menu-content"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-avatar size="42" color="white" v-bind="attrs" v-on="on">
          <v-avatar size="40">
            <v-img
              :src="profilePic"
              :lazy-src="require('@/assets/blank-profile.png')"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    :size="25"
                    :width="5"
                    indeterminate
                    color="primary lighten-5"
                  />
                </v-row>
              </template>
            </v-img>
          </v-avatar>
        </v-avatar>
      </template>

      <v-list>
        <v-list-item-group color="primary">
          <v-list-item
            link
            @click="
              $router.replace({ path: '/dashboard/profile/profileSettings' })
            "
          >
            <v-list-item-content>
              <v-list-item-title class="text-h6 text-capitalize">
                {{ `${user.first_name} ${user.last_name}` }}
              </v-list-item-title>

              <v-list-item-subtitle class="pb-2">
                {{ user.email }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <!-- <v-list-item link>
            <v-list-item-icon>
              <v-icon size="18">{{ icons.mdiAccountOutline }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>Profile</v-list-item-content>
          </v-list-item> -->

          <v-divider></v-divider>

          <v-list-item link @click="logout()">
            <v-list-item-icon>
              <v-icon size="18"> {{ icons.mdiLogoutVariant }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title> Logout </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>
<script>
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
  mdiNotificationClearAll,
} from "@mdi/js";
import { AuthService } from "../../../auth";

export default {
  name: "AppBarTop",
  data: function () {
    return {
      tabs: null,
      selectedItem: 1,
      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiNotificationClearAll,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
      },
      url: process.env.VUE_APP_TEMP_URL,
    };
  },
  computed: {
    links() {
      return this.$store.getters["Dashboard/links"];
    },
    user() {
      return AuthService.user;
    },
    profile() {
      return this.$store.getters["Profile/profileGetters"]("profile");
    },
    profilePic() {
      return this.profile.avatar
        ? `${this.url}/storage/${this.profile.avatar}`
        : require("@/assets/blank-profile.png");
    },
  },
  methods: {
    logout() {
      AuthService.logout();
    },
  },
};
</script>
<style>
.v-app-bar-title__content {
  width: 300px !important;
}
</style>
