<template>
  <!-- eslint-disable vue/valid-v-slot -->
  <v-card width="100%" class="mx-3 pl-12" flat>
    <v-card-title>
      <span class="text-lg-h4">Issues</span>
    </v-card-title>

    <v-card-text>
      <!-- <v-responsive class="mx-auto"> -->
      <div align="start" class="d-flex justify-start">
        <div>
          <v-text-field
            id="searchIssue"
            prepend-inner-icon="fa-solid fa-magnifying-glass"
            outlined
            :filled="searchInput.length <= 0"
            placeholder="Search"
            dense
            v-model="searchInput"
          ></v-text-field>
        </div>
        <div id="issueStatus">
          <ProjectsFilter />
        </div>

        <v-btn id="addIssue" color="primary" depressed @click="dialog = true">
          add issue
        </v-btn>
      </div>

      <v-data-table
        :headers="headers"
        :items="issues"
        light
        calculate-widths
        :search="searchInput"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        @dblclick:row="selectIssue"
        fixed-header
        height="70vh"
      >
        <template v-slot:item.issueType="{ item }">
          <v-chip
            label
            small
            :color="`${issueTypeColor(item.issueType)} lighten-5`"
            :class="`${issueTypeColor(
              item.issueType
            )}--text text--darken-4 text-capitlize`"
            >{{ item.issueType }}</v-chip
          >
        </template>
        <template v-slot:item.title="{ item }">
          <a @click="selectIssue($event, item)">
            <span
              :class="`${issueTypeColor(
                item.issueType
              )}--text text--darken-4 text-capitlize`"
              v-if="item.title"
              v-html="
                item.title.length > 40
                  ? `${item.title.slice(0, 40)}...`
                  : item.title
              "
            />
          </a>
        </template>
        <template v-slot:item.assignee="{ item }">
          <div class="d-flex">
            <v-avatar
              v-if="item.assignee !== null"
              class="mr-2 white--text"
              :size="24"
              :color="userColor(item.assignee)"
            >
              <v-img
                v-if="item.assignee"
                :src="getAssigneeAvatar(item.assignee)"
                contain
              />
            </v-avatar>

            <v-avatar v-else color="grey" class="mr-2 white--text" :size="24" />

            <span class="text-capitalize" v-if="item.assignee !== null">
              {{ getAssignee(item.assignee) }}
            </span>

            <span v-else>_ _ _</span>
          </div>
        </template>
        <template v-slot:item.reporter="{ item }">
          <div class="d-flex" v-if="item.reporter || item.requestedBy">
            <v-avatar
              class="mr-2 white--text"
              :size="24"
              :color="userColor(item.reporter)"
            >
              <v-img
                v-if="item.reporter"
                :src="getReporterAvatar(item.reporter)"
                contain
              />
              <v-img
                v-else-if="item.requestedBy"
                :src="getReporterAvatar(item.requestedBy)"
                contain
              />
            </v-avatar>

            <span class="text-capitalize" v-if="item.reporter">
              {{ getReporterName(item.reporter) }}
            </span>

            <span class="text-capitalize" v-else-if="item.requestedBy">
              {{ getReporterName(item.requestedBy) }}
            </span>
          </div>

          <div v-else>
            <v-avatar
              class="mr-2 white--text"
              :size="24"
              :color="userColor(item.reporter)"
            >
              {{ item.reporter }}
            </v-avatar>

            <span class="text-capitalize">
              {{ item.reporter }}
            </span>
          </div>
        </template>
        <template v-slot:item.priorities="{ item }">
          <v-chip
            label
            small
            :color="`${issuePriorityColor(item.priorities)} lighten-5`"
            :class="`${issuePriorityColor(
              item.priorities
            )}--text text--darken-4 text-capitlize`"
            >{{ item.priorities }}</v-chip
          >
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip
            label
            small
            :color="`${issueStatusColor(item.status)} lighten-5`"
            :class="`${issueStatusColor(
              item.status
            )}--text text--darken-4 text-capitlize`"
            >{{ item.status }}</v-chip
          >
        </template>
        <template v-slot:item.createdDate="{ item }">
          <span>{{ createdDateFormat(item) }}</span>
        </template>
        <template v-slot:item.updatedDate="{ item }">
          <span>{{ dateFormat(item.updatedDate) }}</span>
        </template>
        <template v-slot:item.dueDate="{ item }">
          <span>{{ dateFormat(item.dueDate) }}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-on="on" :attrs="attrs" icon>
                <v-icon size="25">fa-solid fa-ellipsis</v-icon>
              </v-btn>
            </template>

            <v-list dense id="viewIssues">
              <v-list-item
                dense
                v-for="(action, i) in actions"
                :key="i"
                :disabled="action.disabled"
                @click="action.action($event, item)"
              >
                <v-list-item-title>
                  <v-icon color="blue">mdi-pencil</v-icon>
                  {{ action.text }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>

      <!-- </v-responsive> -->
    </v-card-text>

    <v-dialog
      v-model="dialog"
      persistent
      :width="$vuetify.breakpoint.mdAndUp ? '70%' : '100%'"
    >
      <IssuesCard :padding="padding" :dialog="dialog" />
    </v-dialog>
  </v-card>
</template>
<script>
import ProjectsFilter from "./ProjectsFilter.vue";
import IssuesCard from "./IssuesCard.vue";
import issueMixin from "../issueMixin";
import colors from "../styles/colors";
import { helper } from "@/packages/dashboard/utils";
export default {
  name: "IssueList",
  components: {
    IssuesCard,
    ProjectsFilter,
  },
  mixins: [issueMixin],
  data: function () {
    return {
      searchInput: "",
      padding: 5,
      sortBy: "issueCode",
      sortDesc: true,
    };
  },
  beforeMount() {
    this.$store.dispatch("Ticket/getAgents");
    this.$store.dispatch("Setup/getReporters");
    this.$store.dispatch("Ticket/getfilteredCode");
  },
  mounted() {
    Event.$on("getfilteredCode", (filteredItems) => {
      this.$store.dispatch("Ticket/getFilteredIssues", filteredItems);
    });
  },
  computed: {
    dialog: {
      get() {
        return this.$store.getters["Ticket/issueGetters"]("issuesModal");
      },
      set(val) {
        this.$store.dispatch("Ticket/addIssue", val);
      },
    },
    searchField: {
      get() {
        return this.searchInput;
      },
      set(value) {
        this.$emit("searchInput", value);
      },
    },
    headers() {
      return [
        {
          text: "Type",
          value: "issueType",
          align: "start",
          sortable: true,
        },
        {
          text: "No.",
          value: "issueCode",
          align: "start",
          sortable: true,
        },
        {
          text: "Title",
          value: "title",
          align: "start",
          sortable: false,
        },
        {
          text: "Product",
          value: "productCode",
          align: "start",
          sortable: false,
        },
        {
          text: "Module",
          value: "moduleCode",
          align: "start",
          sortable: false,
        },
        {
          text: "Assignee",
          value: "assignee",
          align: "start",
          sortable: true,
        },
        {
          text: "Requested By",
          value: "requestedBy",
          align: "start",
          sortable: true,
        },
        {
          text: "Reporter",
          value: "reporter",
          align: "start",
          sortable: true,
        },
        {
          text: "Priority",
          value: "priorities",
          align: "start",
          sortable: true,
        },
        {
          text: "Status",
          value: "status",
          align: "start",
          sortable: true,
        },
        {
          text: "Created",
          value: "createdDate",
          align: "start",
          sortable: true,
        },
        {
          text: "Due",
          value: "dueDate",
          align: "start",
          sortable: true,
        },
        {
          text: "",
          value: "actions",
          align: "end",
          sortable: true,
        },
      ];
    },
    issues() {
      return this.$store.getters["Ticket/issueGetters"]("issues");
    },
    actions: function () {
      return [
        {
          text: "Edit",
          disabled: false,
          action: (event, item) => {
            this.selectIssue(event, item);
          },
        },
      ];
    },
    agents: function () {
      return this.$store.getters["Ticket/issueGetters"]("agents");
    },
    filteredItems: function () {
      return this.$store.getters["Ticket/issueGetters"]("filteredItems");
    },
  },
  methods: {
    updateSearch(val) {
      this.searchInput = val;
    },
    issueTypeColor(type) {
      return colors.issueType()[type.toLowerCase()];
    },
    userColor(user) {
      if (user) {
        return helper.stringToColour(user);
      }
    },
    issuePriorityColor(priority) {
      return colors.issuePriority()[priority.toLowerCase()];
    },
    getReporterName(val) {
      if (val) {
        const data = this.reporters.filter((d) => d.code === val);
        if (data.length !== 0) {
          return data[0].name;
        } else {
          return "";
        }
      }
    },

    getReporterAvatar(val) {
      if (val) {
        const data = this.reporters.filter((d) => d.code === val);
        if (data.length !== 0) {
          return data[0].avatar
            ? `data:image/png;base64,${data[0].avatar}`
            : require("@/assets/60111.jpg");
        } else {
          return "";
        }
      }
    },
    edit(item) {
      this.$store.dispatch("Ticket/addIssue", true);
      this.$store.dispatch("Ticket/getIssue", item);
    },
    closeDialog: function (value) {
      this.editDialog = value;
    },
    filterIssues(filteredItems) {
      this.loading = true;
      let filteredIssues = [];
      filteredItems.map((item) => {
        item.selectedItems.map((el) => {
          let items = [];
          items.push(this.issues.filter((i) => i[item.itemType] === el.code));
          filteredIssues = filteredIssues.concat(...items);
        });
      });
      this.loading = false;
      this.filteredIssues = filteredIssues.filter(this.onlyUnique);
    },
    getAssignee(code) {
      const agent = this.agents.filter((item) => item.code === code).shift();
      return agent ? agent.name : "";
    },
    getAssigneeAvatar(code) {
      const agent = this.agents.filter((item) => item.code === code).shift();
      return agent ? process.env.VUE_APP_TEMP_URL + agent.avatar : "";
    },
  },
};
</script>
<style lang="sass">
@import '~@/packages/tickets/modules/issues/styles/issues.sass'
</style>
<style scoped>
a:hover {
  text-decoration: underline;
}
</style>
