import { AuthService } from "@/packages/dashboard/modules/auth";
import { helper } from "@/packages/dashboard/utils";
import RouterMixin from "../../../dashboard/mixin/RouterMixin";
import colors from "./styles/colors";

export default {
  mixins: [RouterMixin],
  data: function () {
    return {
      search: "",
      url: process.env.VUE_APP_TEMP_URL,
    };
  },
  computed: {
    customToolbar() {
      return [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
      ];
    },
    selfLoading() {
      return this.$store.getters["Ticket/issueGetters"]("selfLoading");
    },
    selectedItem: {
      get() {
        return this.$store.getters["Ticket/issueGetters"]("selectedItem");
      },
      set(val) {
        this.$store.dispatch("Ticket/updateSelectedItem", val);
      },
    },
    view: {
      get() {
        return this.$store.getters["Ticket/issueGetters"]("layout");
      },
      set(val) {
        this.$store.dispatch("Ticket/changeLayout", val);
      },
    },
    user() {
      return AuthService.user;
    },
    user_id(){
      return AuthService.user_id
    },
    agents: function () {
      return this.$store.getters["Ticket/issueGetters"]("agents");
    },
    Statuses() {
      return this.$store.getters["Setup/setupGetters"]("issueStatus");
    },
    reporters: function () {
      return this.$store.getters["Setup/setupGetters"]("reporters");
    },
  },
  methods: {
    issueTypeColor(type) {
      return colors.issueType()[type];
    },
    userColor(user) {
      return helper.stringToColour(user);
    },
    issuePriorityColor(priority) {
      return colors.issuePriority()[priority.toLowerCase()];
    },
    issueStatusColor(status) {
      if (status) {
        return helper.status()[status.toLowerCase().replace(/\s/g, "")];
      }
    },
    dateFormat(date) {
      return helper.getDateFormat(date);
    },
    createdDateFormat(item) {
      // Your date string
      const dateString = "0001-01-01T00:00:00Z";

      // Convert the date string to a Date object
      const dateToCompare = new Date(dateString);

      // Compare yourDate to the special date
      if (
        new Date(item.jiraCreatedDate).getTime() === dateToCompare.getTime()
      ) {
        return helper.getDateFormat(item.SystemCreatedAt);
      } else {
        return helper.getDateFormat(item.jiraCreatedDate);
      }
    },
    DateTimeFormat(date) {
      return helper.getEntryDateFormat(date);
    },
    async selfUpdate() {
      this.editPriority = false;
      this.addComment = false;
      const image = await this.getbase64Content(
        this.extractImages(document.getElementById("quizOutput"))
      );
      if (image) {
        let data = {
          issueCode: this.selectedItem.issueCode,
        };
        const formData = helper.prepareFormData(data);
        formData.append("base64file", image);
        this.$store.dispatch("Ticket/addAttachment", {
          data: formData,
          code: this.selectedItem.issueCode,
        });
      } else {
        this.selectedItem.description = this.htmlToString(
          document.getElementById("quizOutput")
        );
        console.log(this.selectedItem.description);
        this.$store.dispatch("Ticket/selfUpdate", { ...this.selectedItem });
      }
    },
    selectIssue: function (event, item) {
      if (event.type === "dblclick") {
        const issue = item.item;
        this.$router.replace({
          name: "EditIssueView",
          params: { id: this.encodeRoute(issue.issueCode) },
        });
      } else {
        this.$router.replace({
          name: "EditIssueView",
          params: { id: this.encodeRoute(item.issueCode) },
        });
      }
    },
    refresh: function () {
      this.$store.dispatch("Ticket/getIssues", {
        projectCode: this.user.company,
      });
    },
    updateStatus(status) {
      const data = {
        issueCode: this.selectedItem.issueCode,
        status: status,
        projectCode: this.selectedItem.projectCode,
      };
      this.$confirm.show({
        title: `Mark issue as ${data.status}`,
        text: `Are you sure you want to mark issue ${data.issueCode} as ${data.status}`,
        onConfirm: () => {
          this.$store.dispatch("Ticket/updateStatus", data);
        },
      });
    },
    ifPreview(ext) {
      const array = ["pdf", "jpg", "jpeg", "png"];
      return array.includes(ext);
    },
    pageCount: function (value) {
      if (!value) return 0;
      const bin = atob(value);
      return bin.match(/\/Type\s*\/Page\b/g).length;
    },
  },
};
