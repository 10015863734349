<template>
  <v-container fluid class="mt-4 pr-0">
    <!-- comments -->
    <div v-if="term === 'comments'">
      <!-- add comment -->
      <div class="d-flex" v-if="user.user_code && !editComment">
        <v-avatar
          v-if="user"
          class="mr-2 mt-2 white--text"
          :size="24"
          :color="userColor(user.user_code)"
        >
          {{ `${user.user_code}` }}
        </v-avatar>

        <v-text-field
          v-if="!addComment"
          outlined
          label="Add Comment"
          @click="addComment = !addComment"
          @click.prevent="focusOnEditor"
          readonly
          menu
          dense
          class="mb-n3"
        />

        <div v-if="addComment" class="editor">
          <div>
            <Editor
              class="editor"
              v-model="formData.comment"
              :editorToolbar="customToolbar"
              ref="editor"
            />
          </div>

          <div class="d-flex mt-3">
            <v-btn depressed color="primary" @click="saveComment">save</v-btn>
            <v-btn depressed color="error" text @click="addComment = false">
              cancel
            </v-btn>
          </div>
        </div>
      </div>

      <div v-else>
        <v-avatar class="mr-2 white--text" :size="24" :color="userColor(user)">
          {{ user ? `${user.username.slice(0, 3)}` : "" }}
        </v-avatar>

        <span class="text-capitalize">
          {{ `${user.username}` }}
        </span>
      </div>

      <!-- previous comments -->
      <div
        v-if="issueComments.length !== 0"
        style="max-height: 30vh"
        class="overflow-y-auto"
      >
        <v-list dense v-if="!editComment">
          <v-list-item
            two-line
            class="pa-0"
            v-for="(comment, i) in issueComments.slice().reverse()"
            :key="i"
          >
            <v-tooltip
              top
              :disabled="
                selectedItem.status === 'Closed' ||
                comment.user !== user.user_code
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <v-hover v-slot="{ hover }" v-if="!editComment">
                  <v-btn
                    :text="hover ? false : true"
                    :ripple="hover"
                    class="d-flex black--text text-capitalize ripple text-start pl-0"
                    @click="commentEdition(comment)"
                    plain
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-list-item-avatar>
                      <v-avatar
                        v-if="comment.user"
                        class="mt-n1 white--text"
                        :size="24"
                        :color="userColor(comment.user)"
                      >
                        {{ comment.user }}
                      </v-avatar>

                      <v-avatar
                        v-else
                        class="mr-2 white--text"
                        :size="24"
                        color="grey"
                      />
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-subtitle>
                        {{
                          `${comment.user} ${DateTimeFormat(
                            comment.updated_at
                          )}`
                        }}
                      </v-list-item-subtitle>

                      <v-list-item-title>
                        <span
                          v-html="comment.comment"
                          class="text-capitalize"
                        />
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-btn>
                </v-hover>
              </template>

              <span>Click to edit</span>
            </v-tooltip>
          </v-list-item>
        </v-list>

        <div v-if="editComment">
          <v-avatar class="mt-n1 white--text" :size="24">
            {{ formData.user }}
          </v-avatar>

          <Editor
            v-model="formData.comment"
            :editorToolbar="customToolbar"
            ref="editorComment"
          />

          <div class="d-flex mt-3">
            <v-spacer></v-spacer>

            <v-btn depressed color="primary" @click="saveEditedComment()" icon>
              <v-icon>mdi-check</v-icon>
            </v-btn>

            <v-btn
              depressed
              color="error"
              text
              @click="editComment = false"
              icon
            >
              <v-icon>mdi-cancel</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </div>

    <!-- history -->
    <v-card-text
      v-if="term === 'history'"
      class="overflow-y-auto overflow-x-auto pa-0"
      style="max-height: 30vh"
    >
      <v-list dense>
        <v-list-item
          two-line
          v-for="(log, i) in logs.slice().reverse()"
          :key="i"
          class="pa-0 text-button"
        >
          <v-list-item-avatar>
            <v-avatar class="mr-2 white--text" :size="24" :color="'grey'">
              <v-icon>fa-solid fa-user</v-icon>
            </v-avatar>
          </v-list-item-avatar>

          <v-list-item-content class="text-capitalize">
            <v-list-item-action-text>
              {{ DateTimeFormat(log.dateAndTime) }}
            </v-list-item-action-text>

            <v-list-item-subtitle>
              <div class="wordBreaking">
                <span> {{ log.fieldCaption }}:&nbsp; updated </span>
                <span v-if="log.oldValue">from</span>

                <v-chip
                  label
                  small
                  :color="`red lighten-5`"
                  :class="`red--text text--darken-4 text-capitalize ml-2 text-decoration-line-through`"
                  v-if="log.oldValue"
                >
                  &nbsp;{{
                    log.fieldCaption === "Assignee"
                      ? log.oldValue
                      : log.oldValue
                  }}
                </v-chip>

                <span>&nbsp;to</span>

                <v-chip
                  label
                  small
                  :color="`green lighten-5`"
                  :class="`green--text text--darken-4 text-capitalize ml-2`"
                >
                  {{
                    log.fieldCaption === "Assignee"
                      ? log.newValue
                      : log.newValue
                  }}
                </v-chip>
              </div>
            </v-list-item-subtitle>
          </v-list-item-content>
          <!-- <v-divider inset /> -->
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-container>
</template>
<script>
import { VueEditor as Editor } from "vue2-editor";
import issueMixin from "../issueMixin";

export default {
  name: "Comments",
  mixins: [issueMixin],
  components: { Editor },
  props: { term: String, issue: Object },
  data() {
    return {
      addComment: false,
      editComment: false,
      formData: {
        comment: "",
        issue: "",
        projectCode: "",
        user: "",
        entryNo: "",
      },
    };
  },
  computed: {
    issueComments() {
      return this.issue ? this.issue.issueComments : [];
    },
    logs() {
      return this.issue ? this.issue.issueChangeLogs : [];
    },
  },
  methods: {
    saveComment() {
      this.formData.issue = this.issue.issueCode;
      this.formData.projectCode = this.issue.projectCode;
      this.formData.user = this.user_id;
      delete this.formData.entryNo;
      this.$store.dispatch("Ticket/saveComment", {
        ...this.formData,
      });
      this.addComment = false;
      this.formData.comment = "";
    },
    commentEdition(comment) {
      if (comment.user === this.user.user_code) {
        this.editComment = !this.editComment;
        this.formData = comment;
        this.$nextTick(() => {
          this.$refs.editorComment.quill.focus();
        });
      }
    },
    saveEditedComment() {
      this.$store.dispatch("Ticket/editComment", { ...this.formData });
      this.editComment = false;
    },
    focusOnEditor() {
      this.$nextTick(() => {
        this.$refs.editor.quill.focus();
      });
    },
    getUserName(value) {
      const agent = this.agents
        .filter((element) => {
          return element.code === value;
        })
        .shift();
      return agent.name.split(" ")[0];
    },
  },
};
</script>
<style scoped>
.editor {
  width: 100%;
  height: 60%;
}
</style>
